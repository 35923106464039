
import { defineComponent, ref, watch, onMounted, Ref, handleError } from "vue";

import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import moment from "moment";
import useEmitter from "@/composables/Emmiter";
import FilterBase from "@/layout/header/partials/filters/FilterBase.vue";
import Button from "@/components/Button.vue"
import Api from "@/services/Api";

interface VeiculoFiltroType {
  marca: string;
  modelo: string;
  fipe: string;
  anoInicial: string;
  anoFinal: string;
  comFiltro: boolean | null;
}


export default defineComponent({
  name: "filtro-veiculo",
  components: {
    FilterBase,
    ElConfigProvider,
    Button,
  },

  setup(props, { emit }) {

    const emitter = useEmitter();

    const fecharModal: Ref<boolean> = ref(false);
    const mensagem: Ref<string> = ref('');
    
    const arrayAnosInputFinal = ref<number[]>([])

    function applyMask() {
      veiculoFiltro.value.anoInicial = veiculoFiltro.value.anoInicial.replace(/\D/g, '').slice(0, 4);
      veiculoFiltro.value.anoFinal = veiculoFiltro.value.anoFinal.replace(/\D/g, '').slice(0, 4);
    }
   

    const veiculoFiltro: Ref<VeiculoFiltroType> = ref({marca: "", modelo:"", fipe:"", anoInicial:"", anoFinal:"", comFiltro: null})

     
    const arrayMarcas: Ref<any[]> = ref([])

    function buscar() {

      if(verificaAno()) return;

      emitter.emit('buscar-filtro-veiculo', veiculoFiltro.value)
      fecharModal.value = !fecharModal.value

    }

    function verificaAno(): boolean {

      const { anoInicial, anoFinal} = veiculoFiltro.value

      if(anoFinal && anoInicial > anoFinal) {

        mensagem.value = '*Ano inicial não pode ser maior que o ano final';
        return true

      }

      mensagem.value = ''
      return false

    }

    const getVehicleBrands = async() => arrayMarcas.value = (await Api.get('getAllvehicleBrand')).data.marcas
    

    onMounted(() => {
      getVehicleBrands()
    })

    return { mensagem, buscar, fecharModal, veiculoFiltro, arrayMarcas, arrayAnosInputFinal, applyMask , verificaAno};
  },
});
